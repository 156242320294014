import React, { Component } from 'react';
import Container from '../../common/Container';
import '../../common/Style.css';
import expand from 'assets/expand_icn.png';
import shrink from 'assets/shrink_icn.png';
import congratulations_fisdom from 'assets/ils_covid_success_fisdom.svg';
import congratulations_myway from 'assets/ils_covid_success_myway.svg';
import {
  inrFormatDecimal, storageService
} from '../../../utils/validators';
import Api from 'utils/api';
import { getConfig } from 'utils/functions';
import { nativeCallback } from 'utils/native_callback';
import {Imgc} from 'common/ui/Imgc';
import { isRmJourney } from '../../products/group_health/common_data';

const product_config = {
  'PERSONAL_ACCIDENT': 'You’re insured against any unfortunate accidental events with',
  'HOSPICASH': 'You have successfully insured yourself against Hospital expenses with',
  'SMART_WALLET': 'Your Bank cards & Mobile wallets are insured with',
  'DENGUE': 'You have successfully insured yourself against vector borne diseases with',
  'CORONA': 'You’re insured against coronavirus with',
}

class PlanSuccessClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accordianTab: 'policy',
      lead_data: {
        nominee: {}
      },
      accordians_data: [],
      type: getConfig().productName,
      isGuestUser: storageService().getBoolean('guestUser'),
      isRmJourney: isRmJourney(),
    };

    this.renderAccordions = this.renderAccordions.bind(this);

  }

  componentWillMount() {

    this.setState({
      congratulations_icon: this.state.type !== 'fisdom' ? congratulations_myway : congratulations_fisdom
    })

    let lead_id = window.sessionStorage.getItem('group_insurance_lead_id_selected');
    this.setState({
      lead_id: lead_id || ''
    })

  }

  openInBrowser(url) {
    nativeCallback({
      action: 'open_in_browser',
      message: {
        url: url
      }
    });
  }

  setErrorData = (type) => {

    this.setState({
      showError: false
    });
    if(type) {
      let mapper = {
        'onload':  {
          handleClick1: this.onload,
          button_text1: 'Retry',
          title1: ''
        }
      };
  
      this.setState({
        errorData: {...mapper[type], setErrorData : this.setErrorData}
      })
    }

  }

  onload = async() => {
    this.setErrorData('onload');
    this.setState({
      skelton: true
    })

    let error = '';
    let errorType = '';
    try {
      let url = 'api/insurancev2/api/insurance/bhartiaxa/lead/get/' + this.state.lead_id
      let res = await Api.get(url)
      
      if (res.pfwresponse.status_code === 200) {
        this.setState({
          skelton: false
        })
        let lead_data = res.pfwresponse.result.lead;

        let accordians_data = [
          {
            'key': 'policy',
            'name': 'Policy Info'
          },
          {
            'key': 'personal',
            'name': 'Personal'
          },
          {
            'key': 'address',
            'name': 'Address'
          }
        ]

        if (Object.keys(lead_data.nominee).length !== 0) {
          let obj = {
            'key': 'nominee',
            'name': 'Nominee'
          };

          accordians_data.splice(1, 0, obj);
        }
        const baxa_product_name = lead_data.provider === 'BHARTIAXA' ? 'Bharti AXA General Insurance' : 'ICICI Lombard General Insurance'
        this.setState({
          lead_data: lead_data,
          accordians_data: accordians_data,
          baxa_product_name
        })
      } else {
        error = res.pfwresponse.result.error || res.pfwresponse.result.message
        || true;
      }


    } catch (err) {
      this.setState({
        skelton: false,
      });
      error= true;
      errorType= 'crash';
    }


    // set error data
    if(error) {
      this.setState({
        errorData: {
          ...this.state.errorData,
          title2: error,
          type: errorType
        },
        showError:'page'
      })
    }
  }

  async componentDidMount() {
    this.onload();
  }

  navigate = (pathname) => {
    this.props.parent.props.history.push({
      pathname: pathname,
      search: getConfig().searchParams
    });
  }

  toggleAccordian = (accordianTab) => {
    if (this.state.accordianTab === accordianTab) {
      this.setState({
        accordianTab: ''
      });
      return;
    }
    this.setState({
      accordianTab: accordianTab
    });
  }

  getAddress = (addr) => {
    return (
      <div>
        {addr.addr_line1 + ', ' +
          // addr.landmark + ', ' +
          addr.pincode + ', ' +
          addr.city + ', ' +
          this.capitalize(addr.state) + ', ' +
          this.capitalize(addr.country)
        }
      </div>
    );
  }

  capitalize = (string) => {
    if (!string) {
      return;
    }
    return string.toLowerCase().replace(/(^|\s)[a-z]/g, function (f) { return f.toUpperCase(); })
  }

  renderAccordionBody = (name) => {
    if (name === 'policy') {
      return (
        <div className="AccordionBody">
          <ul>
            <li>Policy: <span>{this.state.lead_data?.policy?.product_title}</span></li>
            <li>Issuer: <span>{this.state.lead_data?.policy?.company_name}</span></li>
            <li>COI: <span>{this.state.lead_data?.policy?.master_policy_number}</span></li>
            <li>Sum assured: <span>{inrFormatDecimal(this.state.lead_data?.cover_amount || 0)}</span></li>
            <li>Cover period: <span>{this.state.lead_data?.product_coverage} yr 
            ({this.state.lead_data?.policy?.policy_start_date} - {this.state.lead_data?.policy?.policy_end_date})</span></li>
            
            {/* <li className="AccordionBodyItem"><span className="AccordionBodyItemBold">Policy</span>: Personal accident</li>
            <li className="AccordionBodyItem"><span className="AccordionBodyItemBold">Issuer</span>: Bharti AXA General Insurances</li>
            <li className="AccordionBodyItem"><span className="AccordionBodyItemBold">COI</span>: CXGHNPOPL456</li>
            <li className="AccordionBodyItem"><span className="AccordionBodyItemBold">Sum Assured</span>: 2 lacs</li>
            <li className="AccordionBodyItem"><span className="AccordionBodyItemBold">Cover period</span>: 1 yr (20 Oct 2019 - 19 Oct 2020)</li> */}
          </ul>
        </div>
      );
    } else if (name === 'personal') {
      return (
        <div className="AccordionBody">
          <ul>
            <li>Name: <span>{this.state.lead_data.name}</span></li>
            <li>DOB: <span>{this.state.lead_data.dob}</span></li>
            <li>Mobile: <span>{this.state.lead_data.mobile_no}</span></li>
            <li>Email: <span>{this.state.lead_data.email}</span></li>
            {this.state.lead_data.product_name !== "SMART_WALLET" && 
            <li>Marital status: <span>
              {this.capitalize(this.state.lead_data.marital_status)}</span></li>
            }
            <li>Gender: <span>{this.capitalize(this.state.lead_data.gender)}</span></li>
          </ul>
        </div>
      );
    } else if (name === 'nominee') {
      return (
        <div className="AccordionBody">
          <ul>
            <li>Name: <span>{this.state.lead_data.nominee.name}</span></li>
            <li>Relationship: <span>{this.capitalize(this.state.lead_data.nominee.relation)}</span></li>
          </ul>
        </div>
      );
    } else if (name === 'address') {
      return (
        <div className="AccordionBody">
          <ul>
            <li>
              Permanent address:
                <div>
                <span style={{ wordWrap: 'break-word' }}>
                  {this.getAddress(this.state.lead_data.permanent_address)}
                </span>
              </div>
            </li>
          </ul>
        </div>
      );
    } else {
      return null;
    }

  }

  renderAccordions(props, index) {
    if(props.name === 'Nominee' && this.state.lead_data && !this.state.lead_data.nominee_details){
      return;
    }
    return (
      <div key={index} className="plan-summary-accordion">
        <div className="accordion-container">
          <div className="Accordion">
            <div className="AccordionTitle" onClick={() => this.toggleAccordian(props.key)}>
                <div className="AccordionList">
                <span className="AccordionList1">
                  <Imgc className="AccordionListIcon" src={(this.state.accordianTab === props.key) ? shrink : expand} alt="" width="20" />
                </span>
                <span className="AccordianTitleValue">{props.name}</span>
              </div>
            </div>
            {this.state.accordianTab === props.key && this.renderAccordionBody(props.key)}
          </div>
        </div>
      </div>
    )
  }

  getProductKey() {
    if (this.props.parent) {
      return this.props.parent.state.product_key;
    }

    return '';
  }

  handleClickOne = () => {
    this.sendEvents('download_policy');
    this.openInBrowser(this.state.lead_data.policy.coi_blob_key);
  }

  handleClickTwo =() => {
    this.sendEvents('check_details');
    storageService().setObject('backToInsuranceLanding', true);
    let path = '/group-insurance/common/reportdetails/' + this.state.lead_data.bhariaxa_policy_id;
    this.navigate(path);
  }

  sendEvents(user_action, insurance_type){
    let eventObj = {
      "event_name": 'Group Insurance',
      "properties": {
        "user_action": user_action,
        "screen_name": 'policy',
        "type": this.props.parent.state.product_key
      }
    };

    if (user_action === 'just_set_events') {
      return eventObj;
    } else {
      nativeCallback({ events: eventObj });
    }
  }

  downloadApp = () =>{
    let url = getConfig().appLink;
    this.openInBrowser(url)
  }

  render() {
    return (
      <Container
        twoButton={!this.state.isGuestUser && !this.state.isRmJourney}
        fullWidthButton={this.state.isGuestUser || this.state.isRmJourney}
        buttonTitle={this.state.isGuestUser || this.state.isRmJourney ? 'DOWNLOAD NOW' : ''}
        onlyButton={this.state.isGuestUser || this.state.isRmJourney}
        noBackIcon={this.state.isGuestUser}
        product_key={this.props.parent ? this.props.parent.state.product_key : ''}
        events={this.sendEvents('just_set_events')}
        buttonOneTitle="Download Policy"
        buttonTwoTitle="Check details"
        showError={this.state.showError}
        errorData={this.state.errorData}
        handleClick={this.downloadApp}
        handleClickOne={this.handleClickOne}
        handleClickTwo={this.handleClickTwo}
        title="Success"
        disableBack={true}
        classOverRideContainer="plan-success"
        skelton={this.state.skelton}
      >
        <div className="plan-success-heading">
          <div className="plan-success-heading-icon"><Imgc className="plan-success-heading-img" src={this.state.congratulations_icon} alt="" /></div>
          <div className="plan-success-heading-title">Congratulations!</div>
          <div className="plan-success-heading-subtitle">{product_config[this.getProductKey()]} <span className="plan-success-heading-subtitle-bold">
            {this.state.lead_data?.policy?.company_name}.</span>
          </div>
        </div>

        {this.state.accordians_data.map(this.renderAccordions)}

        {
          this.state.isGuestUser &&  <p className='download-fisdom-text'>Download {getConfig().productName} app to view all your policy details</p>
        }
      </Container>
    );
  }
}

const PlanSuccess = (props) => (
  <PlanSuccessClass
    {...props} />
);

export default PlanSuccess;