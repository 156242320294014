import Api from 'utils/api';
import isEmpty from 'lodash/isEmpty';
const genericErrMsg = 'Something went wrong';

export const getFeedBackList = async () => {
  try {
    const res = await Api.get('/api/feedback/v2');
    if (res.pfwstatus_code !== 200 || !res.pfwresponse || isEmpty(res.pfwresponse)) {
      throw genericErrMsg;
    }
    const { result, status_code: status } = res.pfwresponse;

    if (status === 200) {
      return result;
    } else {
      throw result.error || result.message || genericErrMsg;
    }
  } catch (err) {
    throw err;
  }
};

export const postFeedBackList = async (params) => {
  try {
    const res = await Api.post('/api/feedback/v2', params);
    if (res.pfwstatus_code !== 200 || !res.pfwresponse || isEmpty(res.pfwresponse)) {
      throw genericErrMsg;
    }
    const { result, status_code: status } = res.pfwresponse;

    if (status === 200) {
      return result;
    } else {
      throw result.error || result.message || genericErrMsg;
    }
  } catch (err) {
    throw err;
  }
};