export const ETF_TERMS_AND_COND = [
  'Under ETSPS(Embracing Trading and settlement Process Scheme) we will credit 1 units of GOLDBEES ETF',
  'ETSPS is applicable to clients at the sole discretion of Finwizard Technology Pvt.Ltd.',
  'The security will be purchased under ETSPS from BSE / NSE',
  'Ledger credit will be given in your trading acccount on previous day EOD which will be approximately, purchase value of security under ETSPS.',
  'Once ledger credit is done, security will be purchased in your account subsequently.',
  'Brokerage and other charges as applicable will be levied on this transaction.',
  'Contract note would be sent on your registered email id.',
  'The security would be transferred to your demat account as per settlement process.',
  'ETSPS terms and conditions are subject to change without any prior notice and Finwizard Technology Pvt.Ltd.reserves the right to discontinue ETSPS without prior notice',
  'The offer available under ETSPS cannot be clubbed or exchanged with any other offers without the prior approval of Finwizard Technology Pvt.Ltd.',
  'As a client, you are giving consent to buy the said securities in your account by accepting terms & conditions of this document and Finwizard Technology Pvt.Ltd.has to right to sell the said securities in case of any violations of said terms & conditions.',
  'Finwizard Technology Pvt.Ltd.in not under any obligations to implement ETSPS and you agree that you do not have any legal right to enforce the same.'
];